/* zte 대응 */
.zte .mathematical-cells {
  width: 1334px;
  height: 800px;
}

.zte .mathematical-cells > *:first-child {
  height: 800px;
  background-size: 1334px 800px;
}

.zte .share-pop-ui {
  width: 1334px;
  height: 800px;
}

/* 신청하기-연관된 학습 */
.zte .knowledge-cell .vis-network .vis-tooltip {
  margin-top: 141px;
  margin-left: 69px;
}
/* MY세포-지식세포들-연관된 학습 */
.zte .mathematical-cells .main-knowledge .area-knowledge-maps .knowledge-cell .vis-network .vis-tooltip {
  margin-top: 62px;
  margin-left: 266px;
}

.zte .challenge-main .area-mission-map {
  height: 626px;
}
.zte .challenge-main .area-mission-map .area-mission-wrap .mission-body {
  padding: 40px 15px 0;
}
.zte .challenge-main .area-mission-map.type-2 {
  height: 696px;
}
.zte .challenge-main .skin-side-mission.type-2 .mission-con-box {
  height: 626px;
}
.zte .challenge-main .skin-side-mission.type-2 .mission-con-box .mission-middle .list-ranking.list-ranking-scroll.act-scroll {
  max-height: 310px;
   height: 310px;
}

.zte .challenge-main .area-result-notice .reward-box {
  margin-top: 30px;
}
.zte .challenge-main .area-result-notice .reward-box .reward-content {
  height: 180px;
}
.zte .challenge-main .area-result-notice .fail-box {
  height: 233px;
  margin-top: 30px;
}
.zte .challenge-main .area-result-detail.type-2 .result-box.result-box-my {
  height: 410px;
  margin-top: 30px;
}
.zte .challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-center-box {
  margin-top: 35px;
}
.zte .challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 + .my-bottom {
  bottom: 0;
}
.zte .challenge-main .skin-main .area-result-ranking .result-box {
  width: 326px;
  height: 610px;
}
.zte .challenge-main .area-result-ranking .ranking-middle .list-ranking.list-ranking-scroll {
  height: 495px;
  max-height: 495px;
}
.zte .challenge-main .area-alarm {
  width: 990px;
}
.zte .challenge-main .area-alarm .alarm-rolling {
  width: 870px;
}
.zte .challenge-main .area-alarm.type-2 {
  width: 840px;
}
.zte .challenge-main .area-alarm.type-2 .alarm-rolling {
  width: 840px;
}

/* 신청하기 */
.zte .join-request .skin-main-ty2 .area-knowledge-maps-ty2 {
  left: 45px;
}
.zte .join-request .skin-side-possible-ty2 {
  right: 70px;
}

/* 학습하기 */
.zte #btnConfirm {
  top: 636px;
}
