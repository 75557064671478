.problem-result {
    font-family: '나눔고딕', 'Nanum Gothic', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}
.problem-result__box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.problem-result__problem li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
}
.problem-result__problem li:last-child {
    margin-right: 0;
}
.problem-result__problem li > span {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    color: #454545;
}
.problem-result__problem li span::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
.problem-result__problem li span.result-o::after {
    background-image: url(../../assets/images/problem-result/ic_mark_correct.png);
}
.problem-result__problem li span.result-x::after {
    background-image: url(../../assets/images/problem-result/ic_mark_discorrect.png);
}
.problem-result__problem li div {
    width: 150px;
    height: 150px;
    background: url(../../assets/images/problem-result/bg_round.png) no-repeat center center / contain;
    display: table;
    text-align: center;
    margin-top: 25px;
}
.problem-result__problem li div b {
    display: table-cell;
    vertical-align: middle;
    color: #000;
    word-break: keep-all;
    font-size: 17px;
    padding: 0 18px;
}
.problem-result__total {
    margin-top: 50px;
}
.problem-result__total li {
    width: 230px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 10px 0px rgba(39,37,37,0.1);
    margin-right: 20px;
}
.problem-result__total li:last-child {
    margin-right: 0;
}
.problem-result__total li > span {
    display: block;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background-color: #f2f2fe;
    color: #9294f3;
    font-weight: 900;
    font-size: 18px;
}
.problem-result__total li > span.background__green {
    background-color: #e7f9fc;
    color: #11bdb5;
}
.problem-result__total li > div {
    height: 75px;
    text-align: center;
    background-color: #fff;
}
.problem-result__total li > div.totalBox__correct span {
    color: #888;
    letter-spacing: 2.5px;
    font-weight: bolder;
}
.problem-result__total li > div span::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
.problem-result__total li > div.totalBox__correct span::before{
    width: 24px;
    height: 24px;
    background-image: url(../../assets/images/problem-result/i_pencil.png);
}
.problem-result__total li > div.totalBox__time span::before{
    width: 22px;
    height: 26px;
    background-image: url(../../assets/images/problem-result/i_clock.png);
}
.problem-result__total li > div.totalBox__note span::before{
    width: 22px;
    height: 26px;
    background-image: url(../../assets/images/problem-result/i_music.png);
}
.problem-result__total li > div span b {
    color: #000;
    font-size: 24px;
    line-height: 73px;
}
.problem-result__btnWrap {
    margin-top: 95px;
}
.problem-result__btnWrap button.btn {
    position: relative;
    width: 220px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-weight: bolder;
    color: #fff;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
}
.problem-result__btnWrap button.btn_commentary {
    background-color: #20cac2;
    margin-right: 50px;
}
.problem-result__btnWrap button.btn_commentary::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    width: 9px;
    height: 14px;
    background: url(../../assets/images/problem-result/i_arrow_right_white.png) no-repeat center center / contain;
}
.problem-result__btnWrap button.btn_finish {
    background-color: #9294f3;
}
.problem-result__btnWrap button.btn_finish::before {
    content: '';
    display: inline-block;
    vertical-align: sub;
    width: 28px;
    height:28px;
    margin-right: 11px;
    background: url(../../assets/images/problem-result/i_check.png) no-repeat center center / contain
}