@charset "utf-8";

* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {border: 0;padding: 0;margin: 0;width:1280px;}

::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-thumb {
	background: #c4bfba;
	border: 1px solid transparent;
	border-radius: 6px;
}
::-webkit-scrollbar-track {
	background: #ede8e1;
	border-radius: 6px;
	border: 1px solid #e8e5e1;
	margin-bottom:4px;
}

/* 공통버튼 */
.button {display: inline-block;font-size: 0px;text-indent: -999px;background-color: #ff00ff;}
.image {display: inline-block;font-size: 0px;text-indent: -999px;background-image: url(../images/4-study-make/test/type_test.png);background-repeat: no-repeat;background-position: 0 0;cursor: pointer;}

/* 이전, 다음 */
#btnPrev {position:absolute;left:0px;bottom:13px;width:53px;height:61px;background-position: 0px 0px;display: none;}
#btnPrev.off{background-position-y: -61px!important;}
#btnNext {position:absolute;left:919px;bottom:13px;width:53px;height:61px;background-position: -53px 0px;display: none;}
#btnNext.off{background-position-y: -61px!important;}

#zteBtnNext {position:absolute;left:974px;bottom:13px;width:53px;height:61px;background-position: -53px 0px;display: none;}
#zteBtnNext.off{background-position-y: -61px!important;}
/* 문제 프레임 */
.challenge-main .skin-main .skin-main-wrap.study-frame {height:100%;padding:0;background:#fff;}
#testFrame {position:absolute;width:972px;height: 100%;box-sizing:border-box;border:none;background: #fff;overflow:auto;}
#zteTestFrame {position:absolute;width: 1026px;height: 100%;box-sizing:border-box;border:none;background: #fff;overflow:auto;}
#testFrame.popup {width: 100%;height: calc(100% - 35px);padding: 30px 80px;}
#zteTestFrame.popup {width: 100%;height: calc(100% - 35px);padding: 30px 80px;}

/* 우측 */
#layoutRight {position:absolute;right:0;top:0;width:308px;height:100%;background-color: #f4f0ff;overflow-x:auto;}
#qNumber {background-color: #ebe4ff;text-align:center;font-size: 0px;padding: 20px 0 13px 0;min-height:150px}
#qNumberList {display: flex;flex-wrap: wrap;justify-content: center;padding: 8px 0 0 0;text-align: left;}
#qNumberList li {box-shadow: 0px 2px 2px 0 rgb(0 0 0 / 15%);border-radius: 10px;background-position: 0px -135px;font-family:'Nanum Gothic';font-weight:700;width:50px;height:50px;margin:7px 0 0 6px;text-indent:0px;font-size:22px;text-align: center;line-height: 50px;color:#454545;}
#qNumberList li:first-child,
#qNumberList li:nth-child(6) {margin-left: 0;}
#qNumberList li.on {background-position:-61px -135px;color:#ffffff !important;}
#qNumberList li.on.correct {background-position:-61px -196px;}
#qNumberList li.on.incorrect {background-position-y: -257px;}
#qNumberList li.finish {background-position:0 -317px;}
#qNumberList li.finish.correct {background-position:0 -196px;}
#qNumberList li.finish.incorrect {background-position: 0px -257px;}
#qNumberList li.on.finish {background-position:-61px -317px;color:#ffffff !important;}
#qNumberList span.inputed {display:inline-block;width: 6px;height: 6px;position: absolute;top:38px;left:22px;background-color: #b4a2fa;border-radius: 6px;opacity: 0.7;} /* 풀이완료 표시 */

/* 문항팝업 내부 문제 */
.start-img {background-color: #f2f2fe; border-radius: 18px; padding: 10px 0; margin: 20px 20px 0;}
.start-img .start-img__inner {display: flex; align-items: center; justify-content: center;}
.start-img .start-img__inner > img { margin-left: 17px; }
.start-img .start-img__inner > img.start-img_equal { width: 27px; }
.start-img .start-img__inner > img.start-img_done { width: 66px; }
.start-img #qNumberList {padding: 0;}
.start-img #qNumberList li {margin-top: 0;}

/* 우측 키패드 */
#keyPadLayout {text-align: center;clear: both;position: absolute;top:179px;}
#keyPadInfo {display:block;width:191px;height: 105px;margin:6px 0 0 57px;background-position: -107px -26px;cursor: auto;}
#keyPad {position:absolute;top:105px;display:block!important;font-size: 0px;text-indent: 0px!important;width:270px;height:314px;margin-left:19px;background-position: 0px -571px;}

#keyPadTab {padding: 2px 2px 0px 2px;}
#keyPadTab li {width:88px;height:49px;display: inline-block;}
#keyPadTab:focus {outline:0;}
#keyPadButton {}
#keyPadButton li {width:65px;height:64px;margin:2px 0px 0px 2px;display: inline-block;}

/* 우측 버튼 */
#btnConfirm {position:absolute;left:26px;top:625px;width:256px;height:66px;background-position-x: -554px;}
#btnConfirm.off {background-position-y: 0px;}
#btnConfirm.on {background-position-y: -66px;}
#btnConfirm.replayTest {background-position-y: -132px;}
#btnConfirm.replayTestResult {background-position-y: -198px;}
#btnConfirm.confirm {background-position-y: -264px;}

/* 성공 이미지 */
#imgSuccess {width:229px;height:267px;background-position: -229px -292px;position: absolute;left:45px;top:307px;display: none;}
#imgSuccess.replayTest {background-position-x: 0px;}
#imgSuccess.replayTestResult {width:211px;height:286px;background-position: -322px 0px;}

/* 토스트 스타일 */
#toast {position:absolute;width:280px;bottom:83px;left:986px;
	font-family: "Nanum Gothic";font-size:20px;font-weight:400;color:#ffffff;line-height: 28px;	text-align: center;
	background-color: #000000;border-radius: 20px;padding: 56px 20px 30px 20px;opacity: 0.7;
	visibility: hidden;
}
#toast .icon {display:block;position:absolute;top:27px;left:129px;width: 22px;height: 22px;background-position: -272px -1px;}
#toast .qNumbers {font-family: "Nanum Gothic";}
#toast.show {visibility: visible;animation: fade-in 700ms,fade-out 700ms 2000ms;/* 700ms동안 fade-in하고 2000ms 딜레이를 갖고 700ms동안 fade-out함 */}
@keyframes fade-in {from {opacity: 0;} to {opacity: 0.7;}}
@keyframes fade-out {from {opacity: 0.7;} to {opacity: 0;}}


/* ---------------------------------------------------------------------- */
/* ---------------------------------------------------------------------- */
/* 수학의 세포들 수정 및 추가 */
/* 220310 cho | 문구 추가 및 수정 */
#layoutRight{
	background-color: #f2f2fe;
}
#qNumber{
	background-color: #e6e6ff;
}
#qNumberInfo {
	display: inline-block;
	width: auto;
	height: auto;
	font-size: 17px;
	font-weight: 800;
	color: #252525;
	letter-spacing: -1.27px;
	/* background-position: -108px -2px; */
}
/* ---------------------------------------------------------------------- */
/* 220310 cho | 키패드 뚜루 이미지 수정 */
#keyPadInfo{
	width: 203px;
	height: 79px;
	margin: 26px 0 0 50px;
  background: url('../images/4-study-make/5-pad/img-keypad@2x.png') no-repeat;
  background-size: 203px 79px;
}
/* 220310 cho | 키패드 이미지 수정 */
#keyPad{
	width: 270px;
	height: 319px;
	background-image: url('../images/4-study-make/5-pad/bg-keypad-tab0@2x.png');
	background-size: 270px 319px;
	background-position: 0;
}
#keyPad.tab0 {background-image: url('../images/4-study-make/5-pad/bg-keypad-tab0@2x.png')}
#keyPad.tab1 {background-image: url('../images/4-study-make/5-pad/bg-keypad-tab1@2x.png')}
#keyPad.tab2 {background-image: url('../images/4-study-make/5-pad/bg-keypad-tab2@2x.png')}
/* ---------------------------------------------------------------------- */
/* 220310 cho | 사이드 하단 버튼 */
#btnConfirm{
	top: 614px;
	left: 26px;
}
/* 활성화 */
#btnConfirm.image{
	width: 260px;
	height: 60px;
	line-height: 60px;
	padding-left: 105px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	border-radius: 15px;
	background-color: #9294f3;
	background-image: none;
	background-position: 0;
	font-size: 22px;
	font-weight: 800;
	color: #ffffff;
	letter-spacing: -1.65px;
	text-indent: 0;
}
/* 답안 제출하기 */
#btnConfirm.image.on,
#btnConfirm.image.off{
	padding-left: 90px;
}
/* 답안 제출하기 활성화 */
#btnConfirm.image.on {
	background-color: #20cac2;
}
/* 답안 제출하기 비활성화 */
#btnConfirm.image.off {
	background-color: #d6d6fb;
	box-shadow: none;
	cursor: none;
}
/* 생각세포 만들기 */
#btnConfirm.image.make {
	padding-left: 73px;
	background-color: #20cac2;
}
/* 틀린문제 다시풀기 */
#btnConfirm.image.replayTest {
	padding-left: 60px;
	background-color: #20cac2;
}
#btnConfirm.image::before{
	position: absolute;
	top: 50%;
	background-image: url('../images/4-study-make/5-pad/i-btn-bullet@2x.png');
	background-size: 38px 147px;
	content: '';
	left: 63px;
	width: 22px;
	height: 24px;
	margin-top: -12px;
	padding-left: 105px;
	background-position: -5px -39px;
	background-repeat: no-repeat;
}
#btnConfirm.image.on::before,
#btnConfirm.image.off::before{
	left: 40px;
	width: 28px;
	height: 28px;
	margin-top: -14px;
	background-position: -5px -73px;
}
#btnConfirm.image.replayTest::before{
	left: 20px;
	width: 25px;
	height: 24px;
	margin-top: -12px;
	background-position: -5px -5px;
}
#btnConfirm.image.make::before{
	left: 35px;
	width: 21px;
	height: 31px;
	margin-top: -15px;
	background-position: -5px -111px;
}
/* 도전완료 */
#imgSuccess.image{
	width: 210px;
	height: 242px;
	background-image: url('../images/4-study-make/5-pad/img-itrue-feedback@2x.png');
	background-size: 210px 725px;
	background-position: 0 -483px;
}
/* 틀린문제 다시풀기 */
#imgSuccess.image.replayTest {
	background-position: 0 -241px;
}
/* 생각세포 만들기 */
#imgSuccess.image.make {
	background-position: 0 0;
}
/* ---------------------------------------------------------------------- */
/* 제한 시간 영역 */
.time-limit-area{
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 1;
	height: 40px;
	line-height: 40px;
	border-radius: 10px;
	background-color: #f2f2fe;
}
.time-limit-area .time-limit-wrap{
	margin: 0 15px;
	font-size: 0;
}
.time-limit-area .info-time{
	position: relative;
	display: inline-block;
	font-family: '나눔고딕', 'Nanum Gothic';
	font-size: 15px;
	font-weight: 600;
	color: #888888;
	letter-spacing: -1.12px;
}
.time-limit-area .info-title{
	padding-left: 28px;
	font-size: 17px;
	font-weight: 800;
	color: #9294f3;
	letter-spacing: -0.63px;
}
.time-limit-area .info-title::before{
	position: absolute;
	left: 0;
	top: 50%;
	width: 23px;
	height: 26px;
	margin-top: -13px;
	background: url('../images/4-study-make/5-pad/i-top-time@2x.png') no-repeat;
	background-size: 23px 26px;
	content: ''
}
.time-limit-area .info-number{
	padding-right: 17px;
	margin-left: 14px;
	font-size: 19px;
	font-weight: 800;
	color: #222222;
}
.time-limit-area .info-number::before{
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 16px;
	margin-top: -8px;
	background-color: #d6d6fb;
	content: ''
}
.time-limit-area .info-txt{
	margin-left: 16px;
  padding-left: 20px;
	font-size: 15px;
	color: #888888;
}
.time-limit-area .info-txt::before{
	position: absolute;
	left: 0;
	top: 50%;
	width: 17px;
	height: 17px;
	margin-top: -8px;
	background: url('../images/4-study-make/5-pad/i-top-notice@2x.png') no-repeat;
	background-size: 17px 17px;
	content: ''
}
/* 상단 버튼 영역 */
.btn-top-area{
	position: absolute;
	top: 20px;
	right: 325px;
	z-index: 1;
}
.btn-top-area .btn-top.btn-know-not{
	height: 40px;
	padding: 0 20px;
	font-family: '나눔고딕', 'Nanum Gothic';
	font-size: 19px;
	font-weight: 800;
	color: #fff;
	letter-spacing: -1.7px;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	background-color: #9294f3;
}

/* 수학의 세포들 고도화 수정 및 추가 | 220725 song 작업 */
/* 학습하기 상단 고정영역 */
.fixd-area {
	display: none;
	position: relative;
	width:972px;
	height: 70px;
	padding: 20px 20px 0 55px;
	box-sizing: border-box;
	background: #fff;
}
.fixd-area.on {
	display: block;
}
.fixd-area .btn-top-area{ /*모르겠어요 버튼*/
	right: 20px;
}
/* ---------------------------------------------------------------------- */

/* 태그영역 수정 */
.tag-area {
	display: flex;
}
.tag-area .info-tag {
	display: inline-block;
	padding: 8px 10px;
	margin-right: 5px;
	font-size: 18px;
	font-weight: 700;
	letter-spacing: -1.8px;
	text-align: left;
	border-radius: 6px;
	background-color: #fff;
}
.tag-area .info-tag.tag-wrong {
	color: #986af5;
	border: solid 2px #dbcbfc;
}
.tag-area .info-tag.tag-hard {
	color: #20cac2;
	border: solid 2px #adeae7;
}
.tag-area .info-tag.tag-not {
	color: #f159ab;
	border: solid 2px #fac5e2;
}
/* ---------------------------------------------------------------------- */


/* 제한시간 수정 */
.fixd-area .time-limit-area {
	left: unset;
	right: 20px;
}
.fixd-area .time-limit-area .info-number {
	padding-right: 0;
	vertical-align: -7px;
}
.fixd-area .time-limit-area .info-number-tip {
	display: inline-block;
    width: 22px;
    height: 22px;
	margin-left: 5px;
    background: #fff url(../images/4-study-make/5-pad/i-top-notice@2x.png) no-repeat;
    background-size: 22px 22px;
	border-radius: 50%;
	vertical-align: middle;
	font-size: 0;
	text-indent: -999em;
	outline: none;
}
.fixd-area .time-limit-area .info-txt {
	display: none;
}
.fixd-area .time-limit-area .info-txt.on {
	display: block;
	position: absolute;
	top: 37px;
	right: 0;
	width: 359px;
	height: 64px;
	padding: 10px 25px;
	margin: 0;
	box-sizing: border-box;
	text-align: center;
	border: solid 1px #d6d6fb;
	background-color: #fff;
}
.fixd-area .time-limit-area .info-txt .info-txt-close {
	position: absolute;
	top: 10px;
	right: 10px;
    width: 15px;
    height: 15px;
	padding: 15px;
	opacity: 0.8;
    background: url(../images/0-common/i-top-close-2@2x.png) no-repeat top right;
    background-size: 15px 15px;
	font-size: 0;
	text-indent: -999em;
	outline: none;
}
.fixd-area .time-limit-area .info-number::before,
.fixd-area .time-limit-area .info-txt::before {
	display: none;
}
/* ---------------------------------------------------------------------- */

/* 아이프레임 영역 수정 */
.fixd-area ~ #testFrame {
	top: 0;
	height: 100%;
	padding-top: 0;
}
.fixd-area.on ~ #testFrame {
	top: 70px;
	height: calc(100% - 70px);
	padding-top: 0;
	overflow: auto;
}
/* ---------------------------------------------------------------------- */

/* 키패드 추가 수정 */
#qNumber.type-2 {
	display: flex;
    align-items: center;
    flex-direction: column;
	justify-content: center;
	padding: 15px 0;
	background: url('../images/4-study-make/5-pad/bg-qNumber@2x.png') no-repeat center top;
	background-size: 308px 186px;
}
#qNumber.type-2 .tit-concept {
	overflow: hidden;
	width: 258px;
	height: 50px;
	padding: 0 10px;
	margin: 10px auto;
	box-sizing: border-box;
	letter-spacing: -1.7px;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 50px;
	font-size: 17px;
	font-weight: 800;
	color: #222;
	border-radius: 10px;
  	border: solid 1px #f5f5ff;
	background-image: linear-gradient(to right, #d6d6fb, #d9d2fd);
}
#qNumber.type-2 #qNumberInfo {
	color: #888888;
}
#qNumber.type-2 #qNumberList {
	padding: 0;
}
/* ---------------------------------------------------------------------- */
/* END 수학의 세포들 고도화 수정 및 추가 | 220725 song 작업 */
