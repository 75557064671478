.mathematical-cells .challenge-main .top-title {
    position: relative;
    text-align: left;
}
.mathematical-cells .challenge-main .top-title .title-math-cells {
    position: absolute;
    top: 14px;
    left: 16px;
    display: inline-block;
    width: 216px;
    height: 42px;
    background-image: url("../images/0-common/title-mathcells-2@2x.png");
    background-position: 0 0;
    background-size: 216px 42px;
    background-repeat: no-repeat;
}
.mathematical-cells .challenge-main .top-title .title-sub {
    position: absolute;
    top: 18px;
    left: 246px;
    display: inline-block;
    padding-left: 17px;
    font-size: 27px;
    font-weight: 800;
    color: #fff;
    letter-spacing: -2.02px;
}
.mathematical-cells .challenge-main .top-title .title-sub::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 2px;
    height: 26px;
    margin-top: -13px;
    background-color: #ffffff;
    opacity: 0.3;
    content: "";
}

.challenge-main .area-alarm {
    position: absolute;
    bottom: 10px;
    left: 30px;
    width: 940px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(197, 197, 237, 0.85);
    background-color: #d6d6fb;
    box-sizing: border-box;
    text-align: center;
}
.challenge-main .area-alarm .alarm-bell {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: #ffffff;
}
.challenge-main .area-alarm .alarm-bell::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 22px;
    height: 26px;
    background-image: url("../images/2-challenge-main/i-alarm-bell@2x.png");
    background-position: 0 0;
    background-size: 22px 26px;
    background-repeat: no-repeat;
    margin-top: -13px;
    margin-left: -12px;
    content: "";
}
.challenge-main .area-alarm .alarm-bell .i-alarm-new {
    position: absolute;
    top: 0;
    right: 0;
}
.challenge-main .area-alarm .alarm-rolling {
    position: absolute;
    top: 0;
    left: 74px;
    width: 850px;
    height: 58px;
    line-height: 58px;
    padding-left: 22px;
    text-align: left;
    overflow: hidden;
}
.challenge-main .area-alarm .alarm-rolling::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 2px;
    height: 30px;
    margin-top: -13px;
    background-color: #c5c5ed;
    content: "";
}
.challenge-main .area-alarm .alarm-rolling .rolling-list {
    position: relative;
}
.challenge-main .area-alarm .alarm-rolling .al-title {
    position: absolute;
    top: 18px;
    left: 0;
    display: inline-block;
    height: 27px;
    line-height: 27px;
    padding: 0 8px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -1.12px;
}
.challenge-main .area-alarm .alarm-rolling .al-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .area-alarm .alarm-rolling .al-content {
    margin-left: 52px;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    letter-spacing: -1.4px;
}
.challenge-main .area-alarm .alarm-rolling .al-content .al-content-wrap {
    width: 100%;
}
.challenge-main .area-alarm .alarm-rolling .al-content .al-content-wrap em {
    vertical-align: initial;
}
.challenge-main .area-alarm .alarm-rolling .al-content .al-content-wrap em.highlihgt-1 {
    color: #654fc4;
}
.challenge-main .area-alarm .alarm-rolling .al-content .al-content-wrap em.highlihgt-2 {
    color: #db3fbb;
}
.challenge-main .area-alarm .alarm-rolling .homvata-img {
    border-radius: 50%;
}
.challenge-main .area-notice {
    position: absolute;
    bottom: 10px;
    left: 30px;
    width: calc(100% - 60px);
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(197, 197, 237, 0.85);
    background-color: #d6d6fb;
    box-sizing: border-box;
    text-align: center;
}
.challenge-main .area-notice .notice-title {
    position: absolute;
    top: 0;
    left: 0;
}
.challenge-main .area-notice .notice-title::before {
    position: absolute;
    top: -6px;
    left: 23px;
    display: inline-block;
    width: 72px;
    height: 65px;
    background-image: url("../images/2-challenge-main/i-alarm-itrue-notice@2x.png");
    background-position: 0 0;
    background-size: 72px 65px;
    background-repeat: no-repeat;
    content: "";
}
.challenge-main .area-notice .notice-title .txt {
    line-height: 60px;
    padding-left: 116px;
    font-weight: 800;
    font-size: 22px;
    color: #222;
    text-align: center;
    letter-spacing: -1.5px;
}
.challenge-main .area-notice .notice-contents {
    position: absolute;
    top: 0;
    left: 233px;
    width: 640px;
    height: 58px;
    line-height: 58px;
    padding-left: 22px;
    text-align: left;
    overflow: hidden;
}
.challenge-main .area-notice .notice-contents::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 2px;
    height: 30px;
    margin-top: -13px;
    background-color: #c5c5ed;
    content: "";
}
.challenge-main .area-notice .notice-contents .notice-list {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    font-size: 0;
    vertical-align: middle;
}
.challenge-main .area-notice .notice-contents .notice-list:first-child {
    margin-left: 0;
}
.challenge-main .area-notice .notice-contents .nl-title,
.challenge-main .area-notice .notice-contents .nl-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.challenge-main .area-notice .notice-contents .nl-title {
    height: 27px;
    line-height: 27px;
    padding: 0 10px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -1.12px;
}
.challenge-main .area-notice .notice-contents .nl-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .area-notice .notice-contents .nl-content {
    margin-left: 6px;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    letter-spacing: -1.4px;
}
.challenge-main .area-btn-mission {
    position: absolute;
    bottom: 10px;
    right: 30px;
}
.challenge-main .area-btn-mission .btn-go-mission {
    width: 260px;
    height: 60px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    background-color: #20cac2;
    background-image: linear-gradient(to top, #00c3b3 0%, #00c3b3 30%, #08ead7 100%);
    font-size: 24px;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1.8px;
}
.challenge-main .area-btn-mission .btn-go-mission[disabled] {
    background-color: #c8c9f9;
    background-image: none;
}
.challenge-main .area-btn-mission .btn-go-mission[disabled] .txt {
    color: #e6e6ff;
}
.challenge-main .area-btn-mission .btn-go-mission[disabled] .txt::before {
    display: inline-block;
    width: 28px;
    height: 22px;
    background-image: url("../images/2-challenge-main/i-btn-mission-go-disabled@2x.png");
    background-position: 0 0;
    background-size: 28px 22px;
    background-repeat: no-repeat;
}
.challenge-main .area-btn-mission .btn-go-mission[disabled]::after {
    display: inline-block;
    width: 9px;
    height: 14px;
    background-image: url("../images/2-challenge-main/i-btn-arrow-disabled@2x.png");
    background-position: 0 0;
    background-size: 9px 14px;
    background-repeat: no-repeat;
    transform: rotate(0);
}
.challenge-main .area-btn-mission .btn-go-mission::after {
    position: absolute;
    top: 50%;
    right: 24px;
    display: inline-block;
    width: 9px;
    height: 14px;
    background-image: url("../images/2-challenge-main/i-btn-arrow@2x.png");
    background-position: 0 0;
    background-size: 9px 14px;
    background-repeat: no-repeat;
    margin-top: -7px;
    transform: rotate(-180deg);
    content: "";
}
.challenge-main .area-btn-mission .btn-go-mission .txt {
    position: relative;
    padding-left: 12px;
}
.challenge-main .area-btn-mission .btn-go-mission .txt::before {
    position: absolute;
    top: 2px;
    left: -27px;
    display: inline-block;
    width: 28px;
    height: 22px;
    background-image: url("../images/2-challenge-main/i-btn-mission-go@2x.png");
    background-position: 0 0;
    background-size: 28px 22px;
    background-repeat: no-repeat;
    content: "";
}
.challenge-main .area-btn-request {
    position: absolute;
    bottom: 15px;
    right: 35px;
}
.challenge-main .area-btn-request .btn-request {
    width: 295px;
    height: 50px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    background-color: #20cac2;
    background-image: linear-gradient(to top, #00c3b3 0%, #00c3b3 30%, #08ead7 100%);
    font-size: 24px;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -1.8px;
}
.challenge-main .area-btn-request .btn-request[disabled] {
    background-color: #c8c9f9;
    background-image: none;
}
.challenge-main .area-btn-request .btn-request[disabled]::after {
    display: none;
}
.challenge-main .area-btn-request .btn-request[disabled] .txt {
    position: relative;
    padding-left: 36px;
    color: #e6e6ff;
}
.challenge-main .area-btn-request .btn-request[disabled] .txt::before {
    position: absolute;
    top: -1px;
    left: 1px;
    display: inline-block;
    width: 30px;
    height: 28px;
    background-image: url("../images/2-challenge-main/i-btn-request-disabled@2x.png");
    background-position: 0 0;
    background-size: 30px 28px;
    background-repeat: no-repeat;
    content: "";
}
.challenge-main .area-btn-request .btn-request::after {
    position: absolute;
    top: 50%;
    right: 24px;
    display: inline-block;
    width: 9px;
    height: 14px;
    background-image: url("../images/2-challenge-main/i-btn-arrow@2x.png");
    background-position: 0 0;
    background-size: 9px 14px;
    background-repeat: no-repeat;
    margin-top: -7px;
    transform: rotate(-180deg);
    content: "";
}
.challenge-main .area-btn-request .btn-request .txt {
    position: relative;
    padding-left: 30px;
}
.challenge-main .area-btn-request .btn-request .txt::before {
    position: absolute;
    top: -2px;
    left: -7px;
    display: inline-block;
    width: 28px;
    height: 27px;
    background-image: url("../images/2-challenge-main/i-btn-request@2x.png");
    background-position: 0 0;
    background-size: 28px 27px;
    background-repeat: no-repeat;
    content: "";
}

.challenge-main {
    width: 100%;
    height: 100%;
    background-color: #e6e6ff;
}
.challenge-main .ico.i-compare-1 {
    display: inline-block;
    width: 190px;
    height: 190px;
    background-image: url("../images/2-challenge-main/i-compare-1@2x.png");
    background-position: 0 0;
    background-size: 190px 190px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-compare-2 {
    display: inline-block;
    width: 190px;
    height: 190px;
    background-image: url("../images/2-challenge-main/i-compare-2@2x.png");
    background-position: 0 0;
    background-size: 190px 190px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-compare-3 {
    display: inline-block;
    width: 190px;
    height: 190px;
    background-image: url("../images/2-challenge-main/i-compare-3@2x.png");
    background-position: 0 0;
    background-size: 190px 190px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-arrow {
    display: inline-block;
    width: 23px;
    height: 32px;
    background-image: url("../images/2-challenge-main/i-arrow-up@2x.png");
    background-position: 0 0;
    background-size: 23px 32px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-alarm-new {
    display: inline-block;
    width: 21px;
    height: 21px;
    background-image: url("../images/2-challenge-main/i-alarm-new@2x.png");
    background-position: 0 0;
    background-size: 21px 21px;
    background-repeat: no-repeat;
}
.challenge-main .skin-main {
    position: relative;
    height: calc(100% - 70px);
}
.challenge-main .skin-main .skin-main-wrap {
    padding: 18px 30px;
}
.challenge-main .skin-main .area-info .notice-top-box .notice-list {
    position: relative;
    display: inline-block;
    margin-right: 18px;
    font-size: 0;
    vertical-align: top;
}
.challenge-main .skin-main .area-info .notice-top-box .nl-title,
.challenge-main .skin-main .area-info .notice-top-box .nl-info {
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.challenge-main .skin-main .area-info .notice-top-box .nl-title {
    height: 27px;
    line-height: 27px;
    padding: 0 9px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -1.12px;
}
.challenge-main .skin-main .area-info .notice-top-box .nl-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #b1b2fd;
    box-sizing: border-box;
    content: "";
}
.challenge-main .skin-main .area-info .notice-top-box .nl-info {
    margin-left: 6px;
    padding-top: 2px;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    letter-spacing: -1.4px;
}
.challenge-main .skin-main .area-info .notice-top-box .nl-info.info2 {
    max-width: 800px;
}
.challenge-main .skin-main .area-knowledge-maps {
    position: relative;
    padding-top: 30px;
}

@-webkit-keyframes motion-side-hide {
    0% {
        visibility: visible;
        right: 0;
    }
    100% {
        visibility: visible;
        right: -410px;
    }
}

@keyframes motion-side-hide {
    0% {
        visibility: visible;
        right: 0;
    }
    100% {
        visibility: visible;
        right: -410px;
    }
}
@-webkit-keyframes motion-side-show {
    0% {
        visibility: visible;
        right: -410px;
    }
    100% {
        visibility: visible;
        right: 0;
    }
}
@keyframes motion-side-show {
    0% {
        visibility: visible;
        right: -410px;
    }
    100% {
        visibility: visible;
        right: 0;
    }
}
.challenge-main .skin-side-mission {
    position: absolute;
    top: 70px;
    right: -410px;
    /* z-index: 1; */
    z-index: 2;
}
.challenge-main .skin-side-mission.act-hide {
    right: -410px;
    visibility: hidden;
    -webkit-animation-name: motion-side-hide;
    animation-name: motion-side-hide;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform-origin: top;
}
.challenge-main .skin-side-mission.act-hide .mission-con-box {
    box-shadow: none;
}
.challenge-main .skin-side-mission.act-show {
    right: 0;
    visibility: hidden;
    -webkit-animation-name: motion-side-show;
    animation-name: motion-side-show;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    transform-origin: top;
}
.challenge-main .skin-side-mission.act-show .mission-btn-box .btn-mission::before {
    transform: rotate(-180deg);
}
.challenge-main .mission-btn-box {
    position: absolute;
    top: 50%;
    left: -30px;
    margin-top: -40px;
}
.challenge-main .mission-btn-box .btn-mission {
    width: 30px;
    height: 80px;
    box-shadow: 0 4px 14px rgba(39, 37, 37, 0.2);
    border-radius: 25px 0 0 25px;
    background-color: #9294f3;
}
.challenge-main .mission-btn-box .btn-mission::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 9px;
    height: 14px;
    background-image: url("../images/2-challenge-main/i-btn-arrow@2x.png");
    background-position: 0 0;
    background-size: 9px 14px;
    background-repeat: no-repeat;
    margin-top: -7px;
    margin-left: -4px;
    content: "";
}
.challenge-main .mission-con-box {
    position: relative;
    display: block;
    width: 410px;
    height: 602px;
    box-shadow: 0 0 20px rgba(39, 37, 37, 0.1);
    border-radius: 60px 0 0 60px;
    background-color: #ffffff;
}
.challenge-main .mission-con-box .mission-top {
    height: 120px;
    border-radius: 60px 0 0 60px;
    background-color: #f2f2fe;
}
.challenge-main .mission-con-box .mission-top .team-img {
    position: absolute;
    top: 15px;
    left: 15px;
}
.challenge-main .mission-con-box .mission-top .team-info {
    position: relative;
    margin-left: 120px;
    padding-top: 25px;
}
.challenge-main .mission-con-box .mission-top .team-info-txt {
    font-size: 0;
}
.challenge-main .mission-con-box .mission-top .team-info-txt .t-title {
    position: relative;
    display: inline-block;
    height: 27px;
    line-height: 27px;
    padding: 0 7px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -0.07px;
}
.challenge-main .mission-con-box .mission-top .team-info-txt .t-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .mission-con-box .mission-top .team-info-txt .t-name {
    width: 210px;
    margin-left: 4px;
    font-weight: 800;
    font-size: 20px;
    color: #222;
    letter-spacing: -1.5px;
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number {
    margin-top: 10px;
    font-size: 0;
    /* 팀 그래프 상단 수정 230811 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
}

.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-number,
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-goal {
    display: inline-block;
    font-weight: 800;
    font-size: 15px;
    color: #444;
    text-align: center;
    letter-spacing: -1.1px;
    color: #9776f2;
    font-weight: 800;
    font-family: '나눔고딕';
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-goal {
    position: relative;
    margin-left: 6px;
    padding-left: 6px;
    font-weight: 400;
    color: #888;
}

.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-present,
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-all {
    display: inline-block;
    font-weight: 800;
    font-size: 15px;
    color: #444;
    text-align: center;
    letter-spacing: -1.1px;
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-all {
    position: relative;
    margin-left: 6px;
    padding-left: 6px;
    font-weight: 400;
    color: #888;
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-number .t-all::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "/";
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-graph {
    margin-top: 8px;
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-graph .graph-bar {
    position: relative;
    width: 150px;
    height: 15px;
    border-radius: 7px;
    overflow: hidden;
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-graph .graph-bar::before {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background-color: #000000;
    opacity: 0.1;
    content: "";
}
.challenge-main .mission-con-box .mission-top .team-info-figure .team-graph .graph-bar .current-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background-color: #9776f2;
    background-image: linear-gradient(to right, #8284f0 0%, #b58dff 100%);
}
.challenge-main .mission-con-box .mission-top .team-info-percent {
    position: absolute;
    top: 56px;
    right: 20px;
    font-weight: 800;
    font-size: 40px;
    color: #8362e0;
    letter-spacing: -3px;
}
.challenge-main .mission-con-box .mission-middle {
    padding: 10px 15px 10px 25px;
}
.challenge-main .mission-con-box .mission-middle .title-ranking {
    position: relative;
    padding-left: 22px;
}
.challenge-main .mission-con-box .mission-middle .title-ranking .txt {
    display: inline-block;
    width: 37px;
    height: 21px;
    background-image: url("../images/2-challenge-main/pop-mission-title-ranking@2x.png");
    background-position: 0 0;
    background-size: 37px 21px;
    background-repeat: no-repeat;
}
.challenge-main .mission-con-box .mission-middle .title-ranking .r-tit {
    display: inline-block;
    width: 37px;
    height: 21px;
    background-image: url("../images/2-challenge-main/pop-mission-title-ranking@2x.png");
    background-position: 0 0;
    background-size: 37px 21px;
    background-repeat: no-repeat;
}
.challenge-main .mission-con-box .mission-middle .title-ranking .r-txt {
    letter-spacing: -1.2px;
    vertical-align: 3px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #444;
    margin-left: 5px;
}
.challenge-main .mission-con-box .mission-middle .title-ranking::before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    display: inline-block;
    width: 16px;
    height: 25px;
    background-image: url("../images/2-challenge-main/pop-mission-i-ranking@2x.png");
    background-position: 0 0;
    background-size: 16px 25px;
    background-repeat: no-repeat;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking-grp {
    position: relative;
    margin-top: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking-grp::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% - 10px);
    height: 2px;
    border-radius: 2px;
    background-color: #9294f3;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking-grp::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(100% - 10px);
    height: 2px;
    border-radius: 2px;
    background-color: #9294f3;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking {
    position: relative;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-mvp.act-scroll .r-list {
    border-bottom: 1px solid #9294f3;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll {
    height: 290px;
    max-height: 290px;
    overflow-y: auto;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar {
    width: 5px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #a0a0a0;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #e5e5e5;
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll.act-scroll::before {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 5px);
    height: 10px;
    background-image: url("../images/2-challenge-main/pop-mission-bg-scroll-repeat@2x.png");
    background-size: 1px 10px;
    background-repeat: repeat-x;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking.list-ranking-scroll.act-scroll .r-list:last-child {
    border-bottom-color: transparent;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: calc(100% - 10px);
    height: 86px;
    font-size: 0;
    border-bottom: 1px solid #dfdfdf;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-mvp .ranking-img {
    border-color: #ffdc00;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-mvp .ranking-img::before {
    position: absolute;
    top: -14px;
    left: 50%;
    display: inline-block;
    width: 30px;
    height: 20px;
    background-image: url("../images/2-challenge-main/pop-mission-i-mvp@2x.png");
    background-position: 0 0;
    background-size: 30px 20px;
    background-repeat: no-repeat;
    margin-left: -15px;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me {
    background-color: #f4fdfc;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-num .num {
    color: #11bdb5;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-img::after {
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 35px;
    height: 15px;
    margin-left: -17px;
    line-height: 15px;
    border-radius: 7px;
    background-color: #26c0ca;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    letter-spacing: -0.9px;
    text-align: center;
    content: "ME";
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-graph .p-percent {
    color: #11bdb5;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar {
    background-color: #11bdb5;
}
/*230802 progressbar 추가(본인그래프)*/
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__200 {
    background-color: #018f8a;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__300 {
    background-color: #005f65;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-num {
    display: inline-block;
    width: 32px;
    text-align: center;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-num .num {
    font-weight: 700;
    font-size: 19px;
    color: #222;
    letter-spacing: -1.4px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-img {
    position: relative;
    display: inline-block;
    border: 3px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-img img {
    border-radius: 50%;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph {
    padding-left: 20px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number {
    position: relative;
    margin-top: -3px;
    font-size: 0;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-present,
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-all,
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-percent {
    display: inline-block;
    font-weight: 800;
    font-size: 15px;
    color: #444;
    text-align: center;
    letter-spacing: -1.1px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-all {
    position: relative;
    margin-left: 4px;
    padding-left: 6px;
    font-weight: 400;
    color: #888;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-all::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "/";
}
/*그래프 상단 수정 230810*/
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-goal {
    display: inline-block;
    font-size: 15px;    
    text-align: center;
    letter-spacing: -1.1px;
    color: #888;
    position: absolute;
    right: 0;
    font-family: '나눔고딕';
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-number {
    display: inline-block;
    font-size: 15px;    
    text-align: center;
    letter-spacing: -1.1px;
    font-weight: 800;
    font-family: '나눔고딕';
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-number {
    color: #9294f3;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .r-list-me .ranking-graph .personal-number .p-number {
    color: #11bdb5;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-percent {
    position: absolute;
    top: 0;
    right: 0;
    color: #9294f3;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-number .p-rate {
    margin-left: 2px;
    vertical-align: bottom;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: -1px;
    text-align: left;
    color: #fc7664;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph {
    margin-top: 4px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph .graph-bar {
    position: relative;
    width: 125px;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph .graph-bar::before {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #000000;
    opacity: 0.1;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #9294f3;
}
/*230802 progressbar 추가*/
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__200 {
    background-color: #665bee;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__300 {
    background-color: #2a13b4;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-emoji {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -25px;
    margin-left: 8px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-emoji .emoji-box {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-emoji .emoji-box::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 50%;
    opacity: 0.05;
    content: "";
}
.challenge-main .mission-con-box .mission-middle .list-ranking .ranking-emoji .emoji-box:last-child {
    margin-right: 0;
}
.challenge-main .mission-con-box .mission-bottom {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    height: 40px;
}
.challenge-main .mission-con-box .mission-bottom .btn-ranking-top {
    width: 100%;
    height: 100%;
}
.challenge-main .mission-con-box .mission-bottom .btn-ranking-top .txt {
    position: relative;
    display: inline-block;
    padding-left: 23px;
    font-weight: 800;
    font-size: 16px;
    color: #9294f3;
    letter-spacing: -0.8px;
}
.challenge-main .mission-con-box .mission-bottom .btn-ranking-top .txt::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 9px;
    background-image: url("../images/2-challenge-main/pop-mission-i-arrow-top@2x.png");
    background-position: 0 0;
    background-size: 14px 9px;
    background-repeat: no-repeat;
    margin-top: -3px;
    content: "";
}

.challenge-main .ico.i-result-success {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-image: url("../images/2-challenge-result/i-result-success@2x.png");
    background-position: 0 0;
    background-size: 200px 200px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-result-mvp {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-image: url("../images/2-challenge-result/i-result-success-mvp@2x.png");
    background-position: 0 0;
    background-size: 200px 200px;
    background-repeat: no-repeat;
}
.challenge-main .ico.i-result-fail {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-image: url("../images/2-challenge-result/i-result-fail@2x.png");
    background-position: 0 0;
    background-size: 200px 200px;
    background-repeat: no-repeat;
}
.challenge-main .skin-main.skin-main-result .skin-main-wrap {
    padding: 0;
}
.challenge-main .skin-main .result-box {
    box-shadow: 0 0 10px rgba(39, 37, 37, 0.1);
    border-radius: 30px;
    background-color: #ffffff;
}
.challenge-main .skin-main .area-result-detail {
    position: absolute;
    top: 25px;
    left: 380px;
}
.challenge-main .skin-main .area-result-detail .result-box {
    width: 550px;
}
.challenge-main .skin-main .area-result-detail .result-box.result-box-team {
    height: 209px;
}
.challenge-main .skin-main .area-result-detail .result-box.result-box-my {
    margin-top: 20px;
    height: 333px;
}
.challenge-main .skin-main .area-result-ranking {
    position: absolute;
    top: 25px;
    right: 30px;
}
.challenge-main .skin-main .area-result-ranking .result-box {
    width: 300px;
    height: 562px;
}
.challenge-main .area-result-notice {
    position: relative;
    width: 380px;
    padding-top: 50px;
    text-align: center;
}
.challenge-main .area-result-notice .result-txt {
    margin-top: 13px;
    font-size: 0;
}
.challenge-main .area-result-notice .result-txt .t-txt {
    font-weight: 800;
    font-size: 41px;
    color: #9294f3;
    letter-spacing: -3.07px;
    margin-right: 10px;
}
.challenge-main .area-result-notice .result-txt .t-txt.t-txt-2 {
    color: #8362e0;
}
.challenge-main .area-result-notice .result-date {
    margin-top: 10px;
}
.challenge-main .area-result-notice .result-date .d-title {
    position: relative;
    display: inline-block;
    height: 27px;
    line-height: 27px;
    padding: 0 7px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -0.07px;
}
.challenge-main .area-result-notice .result-date .d-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .area-result-notice .result-date .d-txt {
    font-weight: 700;
    font-size: 19px;
    color: #444;
    letter-spacing: -1.42px;
    padding-left: 4px;
}
.challenge-main .area-result-notice .reward-box {
    display: none;
    width: 290px;
    margin-top: 20px;
    border-radius: 15px;
    border: 2px solid #c8c9f9;
    box-sizing: border-box;
    overflow: hidden;
}
.challenge-main .area-result-notice .reward-box.act-on {
    display: inline-block;
}
.challenge-main .area-result-notice .reward-box .reward-title {
    display: block;
    height: 40px;
    line-height: 40px;
    border-radius: 13px 13px 0 0;
    background-color: #c8c9f9;
    font-weight: 700;
    font-size: 20px;
    color: #9294f3;
    letter-spacing: -1.5px;
}
.challenge-main .area-result-notice .reward-box .reward-content {
    display: flex;
    height: 150px;
    margin: 0 5px;
    align-items: center;
}
.challenge-main .area-result-notice .reward-box .reward-item-grp {
    display: flex;
    width: 100%;
    max-height: 145px;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.challenge-main .area-result-notice .reward-box .reward-item-grp::-webkit-scrollbar {
    width: 5px;
}
.challenge-main .area-result-notice .reward-box .reward-item-grp::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #9090a0;
}
.challenge-main .area-result-notice .reward-box .reward-item-grp::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #cfcfe5;
}
.challenge-main .area-result-notice .reward-box .reward-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 15px 0;
    text-align: left;
    vertical-align: top;
}
.challenge-main .area-result-notice .reward-box .reward-item + .reward-item {
    margin-top: 0;
}
.challenge-main .area-result-notice .reward-box .reward-item div:nth-child(2) {
    width: 100px;
}
.challenge-main .area-result-notice .reward-box .txt {
    display: block;
    margin-left: 22px;
    line-height: 1.1em;
    font-weight: 700;
    font-size: 16px;
    color: #444;
    letter-spacing: -1.2px;
}
.challenge-main .area-result-notice .reward-box .txt > em {
    color: #8362e0;
}
.challenge-main .area-result-notice .reward-box .img {
    vertical-align: top;
    background-color: white;
    border-radius: 17px;
}
.challenge-main .area-result-notice .reward-box .cells {
    position: relative;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
}
.challenge-main .area-result-notice .reward-box .cells .c-txt {
    display: block;
    margin-top: 16px;
    line-height: 1.2em;
    font-weight: 800;
    font-size: 13px;
    color: #fff;
    letter-spacing: -1.2px;
    text-align: center;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell {
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid rgba(255, 255, 255, 0.85);
    background-color: #57d0eb;
    border-radius: 50%;
    box-sizing: border-box;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell:nth-child(1) {
    top: 48px;
    left: 16px;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell:nth-child(2) {
    top: 57px;
    left: 26px;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell:nth-child(3) {
    top: 61px;
    left: 37px;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell:nth-child(4) {
    top: 57px;
    left: 48px;
}
.challenge-main .area-result-notice .reward-box .cells .c-cell .i-cell:nth-child(5) {
    top: 48px;
    left: 57px;
}
.challenge-main .area-result-notice .fail-box {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 216px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #d6d6fb;
}
.challenge-main .area-result-notice .fail-box.act-on {
    display: inline-flex;
}
.challenge-main .area-result-notice .fail-box .fail-txt {
    line-height: 1em;
}
.challenge-main .area-result-notice .fail-box .fail-txt-2 {
    margin-top: 15px;
}
.challenge-main .area-result-notice .fail-box .fail-txt-3 {
    margin-top: 15px;
}
.challenge-main .area-result-notice .fail-box .f-txt {
    line-height: 1em;
    font-weight: 700;
    font-size: 19px;
    color: #666;
    letter-spacing: -1.4px;
}
.challenge-main .area-result-notice .fail-box .f-txt.f-bold {
    font-weight: 800;
    color: #222;
}
.challenge-main .area-result-detail .mission-state-box {
    height: 80px;
    margin-top: 12px;
    border-radius: 10px;
    background-color: #f2f2fe;
}
.challenge-main .area-result-detail .mission-state-box .state-mission-grp {
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 100%;
}
.challenge-main .area-result-detail .mission-state-box .state-mission-list {
    position: relative;
    width: 50%;
    text-align: center;
}
.challenge-main .area-result-detail .mission-state-box .state-mission-list:first-child::before {
    display: none;
}
.challenge-main .area-result-detail .mission-state-box .state-mission-list::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 1px;
    height: 50px;
    margin-top: -25px;
    background-color: #d6d6fb;
    content: "";
}
.challenge-main .area-result-detail .mission-state-box .state-title {
    display: block;
    margin-top: 13px;
    font-weight: 700;
    font-size: 16px;
    color: #9294f3;
    letter-spacing: -1.2px;
}
.challenge-main .area-result-detail .mission-state-box .state-number {
    margin-top: 6px;
    font-size: 0;
}
.challenge-main .area-result-detail .mission-state-box .state-number .s-present,
.challenge-main .area-result-detail .mission-state-box .state-number .s-percent,
.challenge-main .area-result-detail .mission-state-box .state-number .s-ranking {
    font-weight: 800;
    font-size: 24px;
    color: #222;
    letter-spacing: -1.8px;
}
.challenge-main .area-result-detail .mission-state-box .state-number .s-all,
.challenge-main .area-result-detail .mission-state-box .state-number .s-txt {
    margin-left: 3px;
    font-weight: 700;
    font-size: 20px;
    color: #888888;
    letter-spacing: -1.5px;
}
.challenge-main .area-result-detail .mission-state-box .state-number .s-all::before {
    padding-right: 3px;
    content: "/";
}
.challenge-main .area-result-detail .result-box-team {
    position: relative;
}
.challenge-main .area-result-detail .result-box-team .team-bottom {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: calc(100% - 60px);
}
.challenge-main .area-result-detail .result-box-team .team-img {
    position: absolute;
    top: 10px;
    left: 30px;
}
.challenge-main .area-result-detail .result-box-team .team-info {
    position: relative;
    margin-left: 135px;
    padding-top: 20px;
}
.challenge-main .area-result-detail .result-box-team .team-info .team-info-txt {
    font-size: 0;
}
.challenge-main .area-result-detail .result-box-team .team-info .team-info-txt .t-title {
    position: relative;
    display: inline-block;
    height: 27px;
    line-height: 27px;
    padding: 0 7px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -0.07px;
}
.challenge-main .area-result-detail .result-box-team .team-info .team-info-txt .t-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .area-result-detail .result-box-team .team-info .team-info-txt .t-name {
    display: block;
    width: 390px;
    margin-top: 8px;
    font-weight: 800;
    font-size: 24px;
    color: #222;
    letter-spacing: -1.8px;
}
.challenge-main .area-result-detail .result-box-my .result-box-my-wrap {
    padding: 18px 30px;
}
.challenge-main .area-result-detail .result-box-my .title-result {
    position: relative;
    padding-left: 24px;
    font-weight: 800;
    font-size: 22px;
    color: #222;
    letter-spacing: -1.6px;
}
.challenge-main .area-result-detail .result-box-my .title-result::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 17px;
    height: 21px;
    background-image: url("../images/2-challenge-result/i-title-my@2x.png");
    background-position: 0 0;
    background-size: 17px 21px;
    background-repeat: no-repeat;
    margin-top: -10px;
    content: "";
}
.challenge-main .area-result-detail .result-box-my .my-middle {
    position: relative;
    margin-top: 25px;
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-title {
    position: relative;
    padding-left: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #9294f3;
    letter-spacing: -1.2px;
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-title::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 3px;
    height: 15px;
    margin-top: -9px;
    background-color: #9294f3;
    content: "";
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-btn-detail {
    position: absolute;
    top: -4px;
    right: 0;
    padding-right: 27px;
    font-weight: 700;
    font-size: 16px;
    color: #888;
    letter-spacing: -1.2px;
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-btn-detail::before {
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    width: 22px;
    height: 24px;
    background-image: url("../images/2-challenge-result/i-btn-detail@2x.png");
    background-position: 0 0;
    background-size: 22px 24px;
    background-repeat: no-repeat;
    margin-top: -10px;
    content: "";
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-cells {
    margin-top: 5px;
    height: 72px;
    overflow: hidden;
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-cells .my-cells-grp {
    display: flex;
    flex-wrap: wrap;
}
.challenge-main .area-result-detail .result-box-my .my-middle .m-cells .my-cells-list {
    height: 29px;
    line-height: 29px;
    padding: 0 10px;
    margin-bottom: 7px;
    margin-right: 7px;
    border-radius: 4px;
    background-color: #f2f2fe;
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.8px;
}
.challenge-main .area-result-detail .result-box-my .my-bottom {
    display: none;
    position: absolute;
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
    height: 65px;
    line-height: 58px;
    border-top: 1px solid #d6d6fb;
}
.challenge-main .area-result-detail .result-box-my .my-bottom.act-on {
    display: block;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .notice-txt {
    font-weight: 700;
    font-size: 16px;
    color: #888888;
    letter-spacing: -1.2px;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .notice-txt .n-txt-2 {
    color: #ff7e00;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .btn-notice {
    position: absolute;
    top: 10px;
    right: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 37px 0 26px;
    border-radius: 20px;
    background-color: #9294f3;
    font-weight: 800;
    font-size: 19px;
    color: #fff;
    letter-spacing: -0.7px;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .btn-notice[disabled] {
    background-color: #d6d6fb;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .btn-notice.btn-award {
    background-color: #ffa014;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .btn-notice .txt {
    position: relative;
}
.challenge-main .area-result-detail .result-box-my .my-bottom .btn-notice .txt::before {
    position: absolute;
    top: 50%;
    right: -22px;
    display: inline-block;
    width: 9px;
    height: 14px;
    background-image: url("../images/2-challenge-result/i-notice-arrow@2x.png");
    background-position: 0 0;
    background-size: 9px 14px;
    background-repeat: no-repeat;
    margin-top: -7px;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle {
    padding: 20px 15px 20px 25px;
}
.challenge-main .area-result-ranking .ranking-middle .title-ranking {
    position: relative;
    padding-left: 22px;
}
.challenge-main .area-result-ranking .ranking-middle .title-ranking .txt {
    display: inline-block;
    width: 37px;
    height: 21px;
    background-image: url("../images/2-challenge-result/mission-title-ranking@2x.png");
    background-position: 0 0;
    background-size: 37px 21px;
    background-repeat: no-repeat;
}
.challenge-main .area-result-ranking .ranking-middle .title-ranking::before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    display: inline-block;
    width: 16px;
    height: 25px;
    background-image: url("../images/2-challenge-result/mission-i-ranking@2x.png");
    background-position: 0 0;
    background-size: 16px 25px;
    background-repeat: no-repeat;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking-grp {
    position: relative;
    margin-top: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking-grp::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% - 10px);
    height: 2px;
    border-radius: 2px;
    background-color: #9294f3;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking-grp::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(100% - 10px);
    height: 2px;
    border-radius: 2px;
    background-color: #9294f3;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking {
    position: relative;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking.list-ranking-scroll {
    height: 447px;
    max-height: 447px;
    overflow-y: auto;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar {
    width: 5px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #a0a0a0;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking.list-ranking-scroll::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #e5e5e5;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: calc(100% - 10px);
    height: 88px;
    font-size: 0;
    border-bottom: 1px solid #dfdfdf;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list:last-child {
    border-bottom: 0 none;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-mvp .ranking-img {
    border-color: #ffdc00;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-mvp .ranking-img::before {
    position: absolute;
    top: -14px;
    left: 50%;
    display: inline-block;
    width: 30px;
    height: 20px;
    background-image: url("../images/2-challenge-result/mission-i-mvp@2x.png");
    background-position: 0 0;
    background-size: 30px 20px;
    background-repeat: no-repeat;
    margin-left: -15px;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me {
    background-color: #f4fdfc;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-num .num {
    color: #11bdb5;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-img::after {
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 35px;
    height: 15px;
    margin-left: -17px;
    line-height: 15px;
    border-radius: 7px;
    background-color: #26c0ca;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    letter-spacing: -0.9px;
    text-align: center;
    content: "ME";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-graph .p-percent {
    color: #11bdb5;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar {
    background-color: #11bdb5;
}
/*230802 progressbar 추가 (본인 그래프 바)*/
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__200 {
    background-color: #018f8a;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list.r-list-me .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__300 {
    background-color: #005f65;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-num {
    display: inline-block;
    width: 32px;
    text-align: center;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-num .num {
    font-weight: 700;
    font-size: 19px;
    color: #222;
    letter-spacing: -1.4px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-img {
    position: relative;
    display: inline-block;
    border: 3px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-img img {
    border-radius: 50%;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph {
    padding-left: 20px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number {
    position: relative;
    margin-top: -3px;
    font-size: 0;
}
/* 그래프 상단 변경 230810 */
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-number {
    display: inline-block;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
    letter-spacing: -1.1px;
    font-family: '나눔고딕';
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .r-list-me .ranking-graph .personal-number .p-number {
    color: #11bdb5;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-goal {
    font-weight: 400;
    color: #888;
    font-size: 15px;
    position: absolute;
    right: 0;
    font-family: '나눔고딕';
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-present,
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-all,
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-percent {
    display: inline-block;
    font-weight: 800;
    font-size: 15px;
    color: #444;
    text-align: center;
    letter-spacing: -1.1px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-all {
    position: relative;
    margin-left: 4px;
    padding-left: 6px;
    font-weight: 400;
    color: #888;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-all::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "/";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-number .p-percent {
    position: absolute;
    top: 0;
    right: 0;
    color: #9294f3;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .p-rate {
    margin-left: 2px;
    vertical-align: bottom;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: -1px;
    text-align: left;
    color: #fc7664;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph {
    margin-top: 4px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph .graph-bar {
    position: relative;
    width: 125px;
    height: 8px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph .graph-bar::before {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #000000;
    opacity: 0.1;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #9294f3;
}
/*230802 progressbar 추가*/
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__200 {
    background-color: #665bee;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-graph .personal-graph .graph-bar .current-bar.current-bar__300 {
    background-color: #2a13b4;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-emoji {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -25px;
    margin-left: 8px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-emoji .emoji-box {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-emoji .emoji-box::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 50%;
    opacity: 0.05;
    content: "";
}
.challenge-main .area-result-ranking .ranking-middle .list-ranking .ranking-emoji .emoji-box:last-child {
    margin-right: 0;
}
.challenge-main .area-result-ranking .ranking-bottom {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    height: 40px;
}
.challenge-main .area-result-ranking .ranking-bottom .btn-ranking-top {
    width: 100%;
    height: 100%;
}
.challenge-main .area-result-ranking .ranking-bottom .btn-ranking-top .txt {
    position: relative;
    display: inline-block;
    padding-left: 23px;
    font-weight: 800;
    font-size: 16px;
    color: #9294f3;
    letter-spacing: -0.8px;
}
.challenge-main .area-result-ranking .ranking-bottom .btn-ranking-top .txt::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 9px;
    background-image: url("../images/2-challenge-result/mission-i-arrow-top@2x.png");
    background-position: 0 0;
    background-size: 14px 9px;
    background-repeat: no-repeat;
    margin-top: -3px;
    content: "";
}

.motion-sequence {
    position: relative;
    display: inline-block;
}
.motion-sequence .ico.i-arrow {
    display: inline-block;
    width: 9px;
    height: 15px;
    background-image: url("../images/2-challenge-main/pop-i-txt-arrow@2x.png");
    background-position: 0 0;
    background-size: 9px 15px;
    background-repeat: no-repeat;
}
.motion-sequence .motion-txt {
    margin-top: 0;
}
.motion-sequence .motion-txt .m-txt {
    display: block;
    line-height: 1.4em;
    font-size: 30px;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: -2.2px;
}
.motion-sequence .motion-txt .m-txt.m-txt-2 {
    margin-top: 22px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -1.3px;
}
.motion-sequence .motion-txt .m-txt .t-highlight {
    color: #ffe827;
    vertical-align: baseline;
}
.motion-sequence .motion-txt .m-txt .i-arrow {
    margin: 0 4px 0 6px;
    vertical-align: middle;
}
.motion-sequence .motion-btn {
    margin-top: 35px;
    text-align: center;
}
.motion-sequence .motion-btn .btn-confirm {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 72px;
    border-radius: 25px;
    background-color: #20cac2;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    letter-spacing: -1.8px;
}

.challenge-main .skin-pop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.challenge-main .skin-pop::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    content: "";
}
.challenge-main .skin-pop.act-on {
    display: block;
}
.challenge-main .skin-pop .skin-pop-wrap {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
}
.challenge-main .skin-pop .pop-box {
    position: relative;
    width: 1210px;
    height: 680px;
    background-color: #fff;
    border-radius: 20px;
}
.challenge-main .skin-pop .pop-box .top-area {
    position: absolute;
    top: 20px;
    right: 0;
    width: 100%;
    text-align: center;
}
.challenge-main .skin-pop .pop-box .top-area .pop-title {
    font-size: 30px;
    font-weight: 800;
    color: #9294f3;
    letter-spacing: -2.25px;
}
.challenge-main .skin-pop .pop-box .content-area {
    position: relative;
    height: 512px;
    margin: 83px 15px 0;
    border-radius: 25px;
    background-color: #f2f2fe;
    overflow: hidden;
}
.challenge-main .skin-pop .pop-box-alarm {
    position: relative;
    width: 850px;
    height: auto;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 20px;
}
.challenge-main .skin-pop .pop-box-alarm .top-area {
    position: absolute;
    top: 20px;
    right: 0;
    width: 100%;
    text-align: center;
}
.challenge-main .skin-pop .pop-box-alarm .top-area .pop-title {
    font-size: 30px;
    font-weight: 800;
    color: #9294f3;
    letter-spacing: -2.25px;
}
.challenge-main .skin-pop .pop-box-alarm .content-area {
    position: relative;
    margin: 83px 15px 0;
    border-radius: 25px;
    background-color: #f2f2fe;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box {
    position: relative;
    padding: 10px 0;
    margin: 0 10px 0 20px;
    border-radius: 25px;
    background-color: #f2f2fe;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list-grp {
    display: block;
    min-height: 310px;
    max-height: 310px;
    padding-right: 10px;
    overflow-y: auto;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list-grp::-webkit-scrollbar {
    width: 10px;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list-grp::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #a0a0a0;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list-grp::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #e5e5e5;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list {
    position: relative;
    display: block;
    width: 100%;
    min-height: 16px;
    padding: 15px 0;
    background-image: url("../images/2-challenge-main/pop-alarm-bg-dash-line-repeat@2x.png");
    background-size: 5px 1px;
    background-repeat: repeat-x;
    background-position: 0 bottom;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list .al-content .al-content-wrap em {
    vertical-align: initial;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list .al-content .al-content-wrap em.highlihgt-1 {
    color: #654fc4;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .alarm-list .al-content .al-content-wrap em.highlihgt-2 {
    color: #db3fbb;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-title {
    position: absolute;
    top: 20px;
    left: 0;
    display: inline-block;
    min-width: 26px;
    height: 27px;
    line-height: 27px;
    padding: 0 8px;
    font-weight: 800;
    font-size: 15px;
    color: #9294f3;
    text-align: center;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-title::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 2px solid #9294f3;
    opacity: 0.5;
    box-sizing: border-box;
    content: "";
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content {
    display: block;
    width: calc(100% - 65px);
    margin-left: 52px;
    line-height: 1.6em;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .al-content-wrap {
    width: 100%;
    font-size: 0;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .homvata-img {
    border-radius: 50%;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt {
    line-height: 1.6em;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    letter-spacing: -1.4px;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt.txt-percent {
    margin-left: 10px;
    font-size: 24px;
    font-weight: 800;
    color: #8362e0;
    letter-spacing: -1.8px;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt.txt-number {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt.txt-number::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 18px;
    margin-top: -8px;
    background-color: #999999;
    content: "";
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt.txt-number .t-all {
    position: relative;
    margin-left: -3px;
    font-weight: 400;
    color: #888;
}
.challenge-main .skin-pop .pop-box-alarm .content-area .alarm-box .al-content .txt.txt-number .t-all::before {
    line-height: 1;
    padding: 0 2px 0 3px;
    content: "/";
}

.challenge-main .skin-main-bg {
    background-image: url("../images/2-challenge-main/bg-main@2x.webp");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.challenge-main .skin-main-bg .skin-main-wrap {
    padding: 18px 25px 18px 22px;
}
.challenge-main .area-info .info-cells {
    margin-top: 15px;
}
.challenge-main .area-info .info-cells span {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -1px;
    color: #888;
}
.challenge-main .area-info .info-cells span::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
}
.challenge-main .area-info .info-cells span.cell-current::before {
    border: solid 3px rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    background-color: #986af5;
}
.challenge-main .area-info .info-cells span.cell-pver::before {
    border: solid 3px rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    background-color: #f46dd3;
}
.challenge-main .area-info .info-cells span.cell-chk::before {
    display: inline-block;
    width: 16px;
    height: 18px;
    background-image: url("../images/2-challenge-main/i-cell-chk-info@2x.png");
    background-position: 0 0;
    background-size: 16px 18px;
    background-repeat: no-repeat;
}
.challenge-main .area-knowledge-maps-ty2 {
    position: relative;
    width: 810px;
    height: 515px;
}
.challenge-main .area-knowledge-maps-ty2 .vis-network canvas {
    padding-bottom: 1px;
}
.challenge-main .area-mission-map {
    display: block;
    position: absolute;
    top: 20px;
    right: 25px;
    width: 410px;
    height: 578px;
    text-align: center;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header {
    display: flex;
    padding: 15px 21px 10px 25px;
    border-radius: 30px 30px 0 0;
    background-color: #f2f2fe;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status {
    overflow: hidden;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 8px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 25px;
    z-index: 1;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-sizing: border-box;
    border: solid 6px #e9d8ff;
    background-color: transparent;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    transform: translateZ(0);
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-70 .s-txt .s-percent, .challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-80 .s-txt .s-percent, .challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-90 .s-txt .s-percent {
    color: #fff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-wave {
    position: absolute;
    top: 0 !important;
    left: 0;
    z-index: 2;
    opacity: 1;
    margin: 0;
    width: 90px;
    height: 90px;
    background-image: url("../images/2-challenge-main/i-mission-complete-all@2x.png");
    background-position: 0 0;
    background-size: 90px 90px;
    background-repeat: no-repeat;
    display: block;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-wave.two, .challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-wave.three {
    display: none;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-txt .s-percent {
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100::after {
    background: #e9d8ff;
}
@-webkit-keyframes rotate-vert-center {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes rotate-vert-center {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(360deg);
    }
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status .s-wave {
    opacity: 0.4;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: #fff;
    width: 120px;
    height: 120px;
    margin-left: -62px;
    margin-top: -50px;
    transform-origin: 50% 48%;
    border-radius: 43%;
    -webkit-animation: wave-drift 4s infinite linear;
    animation: wave-drift 4s infinite linear;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-wave {
    -webkit-animation: none;
    animation: none;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status.act-100 .s-wave.effect {
    -webkit-animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: rotate-vert-center 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status .s-wave.two {
    -webkit-animation: wave-drift 8s infinite linear;
    animation: wave-drift 8s infinite linear;
    opacity: 1;
    background: #fff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status .s-wave.three {
    -webkit-animation: wave-drift 6s infinite linear;
    animation: wave-drift 6s infinite linear;
    opacity: 0.3;
    background: #fff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status::after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    z-index: 1;
    width: 99%;
    height: 99%;
    border-radius: 50%;
    background-image: linear-gradient(to right, #fb69ff, #d46ff5);
}
@-webkit-keyframes wave-drift {
    from {
        transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
    }
}
@keyframes wave-drift {
    from {
        transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
    }
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status .s-txt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 100%;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-status .s-status .s-txt .s-percent {
    font-weight: 800;
    font-size: 27px;
    letter-spacing: -4px;
    color: #8362e0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message {
    width: calc(100% - 98px);
    margin-top: 10px;
    text-align: left;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message .m-tit {
    letter-spacing: -2.2px;
    font-size: 20px;
    font-weight: 800;
    color: #222;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message .m-tit strong {
    color: #9775f4;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message .m-txt {
    display: inline-block;
    padding: 8px 29px;
    margin-top: 11px;
    letter-spacing: -1.5px;
    font-size: 16px;
    font-weight: 800;
    color: #444;
    border-radius: 17px;
    background-color: #fff;
    min-width: 80px;
    text-align: center;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message .m-txt em {
    position: relative;
    margin-left: 4px;
    padding-left: 8px;
    font-weight: 300;
    color: #888;
    vertical-align: top;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-header .mission-message .m-txt em::before {
    position: absolute;
    top: 1px;
    left: 0;
    content: "/";
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body {
    padding: 15px 15px 0;
    border-radius: 0 0 30px 30px;
    background: #fff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list {
    display: flex;
    position: relative;
    padding: 0 29px 12px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li {
    position: relative;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin-right: 27px;
    box-sizing: border-box;
    font-size: 0;
    text-indent: -999em;
    border-radius: 50%;
    background-color: #fff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li.i-m-start {
    z-index: 2;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li.i-m-start + li.i-m-complete {
    z-index: 1 !important;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li span {
    position: relative;
    z-index: 1;
    display: inline-block;
    background-image: url("../images/2-challenge-main/i-mission-lock@2x.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: 24px 27px;
    background-position: center;
    background-color: #fff;
    border: solid 6px #e9d8ff;
    box-sizing: border-box;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li::after {
    content: "";
    position: absolute;
    top: 23px;
    z-index: 0;
    width: 30px;
    border: solid 3px #ede0ff;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li:first-child::after {
    display: none;
}
/*혼자챌린지 아이콘 변경*/
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li.i-m-start span {
    width: 100%;
    height: 100%;
    line-height: 48px;
    font-size: 0;
    font-weight: 600;
    color: #fff;
    text-indent: 0;
    background-image: url(../../assets/images/2-challenge-main/i-cell-today.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    -webkit-animation: m-start 0.6s ease-in-out both;
    animation: m-start 0.6s ease-in-out both;
}
/* .challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li.i-m-start span {
    width: 100%;
    height: 100%;
    line-height: 48px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    text-indent: 0;
    background: #fff none;
    border: solid 6px #e9d8ff;
    -webkit-animation: m-start 0.6s ease-in-out both;
    animation: m-start 0.6s ease-in-out both;
} */
@-webkit-keyframes m-start {
    0% {
        background-color: #fff;
    }
    20% {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        background-color: #dd77ff;
    }
}
@keyframes m-start {
    0% {
        background-color: #fff;
    }
    20% {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        background-color: #dd77ff;
    }
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list li.i-m-complete span {
    display: inline-block;
    background-image: url("../images/2-challenge-main/i-mission-complete@2x.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: 25px 27px;
    background-position: center;
    font-size: 0;
    text-indent: -999em;
    background-color: #dd77ff;
    border: solid 6px #e9d8ff;
    -webkit-animation: m-complete 0.8s ease-in-out both;
    animation: m-complete 0.8s ease-in-out both;
}
@-webkit-keyframes m-complete {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.2);
    }
    50% {
        transform: rotate(0);
    }
    100% {
        transform: scale(1);
        transform: rotate(360deg);
    }
}
@keyframes m-complete {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.2);
    }
    30% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.2);
    }
    50% {
        transform: rotate(0);
    }
    100% {
        transform: scale(1);
        transform: rotate(360deg);
    }
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-left {
    flex-direction: row-reverse;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-left li:first-child {
    margin-right: 0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-left li::after {
    left: 52px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-right {
    flex-direction: row;
    justify-content: flex-start;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-right li:last-child {
    margin-right: 0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-right li::after {
    right: 52px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list:nth-child(2n) li:nth-child(1) {
    margin-right: 0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list:nth-child(2n) li:nth-child(4) {
    margin-right: 27px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-right:nth-child(2n) li:nth-child(1) {
    margin-right: 27px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list.ml-right:nth-child(2n) li:last-child {
    margin-right: 0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list:nth-child(2n)::before {
    left: unset;
    right: 0;
    border-right: solid 6px #ede0ff;
    border-left: 0;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list::before {
    content: "";
    position: absolute;
    top: -47px;
    left: 0;
    z-index: 0;
    width: 70px;
    height: 70px;
    border: solid 6px #ede0ff;
    border-right: 0;
    border-radius: 60px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-body .mission-list:first-child::before {
    display: none;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-desc.padding-top {
    padding-top: 30px;
}
.challenge-main .area-mission-map .area-mission-wrap .mission-desc p {
    padding-top: 20px;
    margin: 0 10px 12px;
    text-align: center;
    border-top: 1px dashed rgba(153, 129, 203, 0.3);
}
.challenge-main .area-mission-map .area-mission-wrap .mission-desc p.border-bottom {
    border-bottom: 1px dashed rgba(153, 129, 203, 0.3);
}
.challenge-main .area-mission-map .area-mission-wrap .mission-desc p > span {
    display: block;
    margin: 15px 0 25px;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: -0.5px;
}
@-webkit-keyframes show-sc {
    from {
        transform: scale(0.2);
        opacity: 0;
    }
    to {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes show-sc {
    from {
        transform: scale(0.2);
        opacity: 0;
    }
    to {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
    }
}
.challenge-main .area-mission-map .area-mission-wrap .area-btn-mission {
    bottom: -74px;
    right: 0;
    transform: scale(0.2);
    opacity: 0;
    -webkit-animation: show-sc 0.4s ease-in-out forwards;
    animation: show-sc 0.4s ease-in-out forwards 0.4s;
}
.challenge-main .area-mission-map.type-2 {
    height: 648px;
}
.challenge-main .area-mission-map.type-2 .area-mission-wrap .area-btn-mission {
    bottom: 20px;
    right: 72px;
}
.challenge-main .skin-side-mission.type-2 {
    top: 90px;
    right: -435px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box {
    width: 435px;
    height: 578px;
    border-radius: 30px 0 0 30px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box > section {
    padding-right: 20px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box .mission-top {
    border-radius: 30px 0 0 0;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box .mission-top .team-info-txt .t-name {
    width: 300px;
    margin-left: 0;
    font-size: 18px;
    letter-spacing: -2px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box .mission-middle .list-ranking.list-ranking-scroll.act-scroll {
    height: 262px;
    max-height: 262px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box .mission-middle .list-ranking.list-ranking-scroll {
    height: 348px;
    max-height: 348px;
}
.challenge-main .skin-side-mission.type-2 .mission-con-box .mission-middle .list-ranking.list-ranking-scroll .r-list:last-child {
    border-bottom-color: transparent;
}
.challenge-main .skin-side-mission.type-2.act-hide {
    right: -435px;
    -webkit-animation-name: motion-side-hide2;
    animation-name: motion-side-hide2;
    -webkit-animation-duration: 0s;
    animation-duration: 0s;
}
.challenge-main .skin-side-mission.type-2.act-hide.active {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}
@-webkit-keyframes motion-side-hide2 {
    0% {
        visibility: visible;
        right: 0;
    }
    100% {
        visibility: visible;
        right: -435px;
    }
}
@keyframes motion-side-hide2 {
    0% {
        visibility: visible;
        right: 0;
    }
    100% {
        visibility: visible;
        right: -435px;
    }
}
.challenge-main .area-alarm.type-2 {
    position: absolute;
    bottom: 10px;
    left: 30px;
    width: 800px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(197, 197, 237, 0.85);
    background-color: #d6d6fb;
    box-sizing: border-box;
    text-align: center;
}
.challenge-main .area-alarm.type-2 .alarm-bell {
    display: none;
}
.challenge-main .area-alarm.type-2 .alarm-rolling {
    position: absolute;
    top: 0;
    left: 0;
    width: 800px;
    height: 58px;
    padding: 0 30px;
    box-sizing: border-box;
    line-height: 58px;
    text-align: left;
    overflow: hidden;
}
.challenge-main .area-alarm.type-2 .alarm-rolling::before {
    display: none;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .rolling-list {
    position: relative;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .al-title {
    display: none;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .al-content {
    margin: 0;
    font-size: 19px;
    font-weight: 700;
    color: #444;
    letter-spacing: -1.4px;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .al-content .al-content-wrap {
    width: 100%;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .al-content .al-content-wrap em.highlihgt-1 {
    color: #654fc4;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .al-content .al-content-wrap em.highlihgt-2 {
    color: #db3fbb;
}
.challenge-main .area-alarm.type-2 .alarm-rolling .homvata-img {
    border-radius: 50%;
}

.challenge-main .area-result-notice.type-2 .reward-box .cells {
    position: relative;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub {
    display: inline-block;
    background-image: url("../images/0-common/i-cell-n-type-2@2x.png");
    background-size: 100px 100px;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-2-finish@2x.png");
    background-position: 0 0;
    background-size: 80px 80px;
    background-repeat: no-repeat;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish .c-txt {
    color: #fff;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish .c-cell .i-cell.step1 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish .c-cell .i-cell.step2 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish .c-cell .i-cell.step3 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub.finish .c-cell .i-cell.step4 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-txt {
    all: unset;
    display: table-cell;
    table-layout: fixed;
    width: 80px;
    height: 80px;
    padding: 0 20px;
    line-height: 1.2em;
    font-weight: 800;
    font-size: 15px;
    color: #000;
    letter-spacing: -2.2px;
    vertical-align: middle;
    text-align: center;
    word-break: keep-all;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-cell .i-cell {
    all: unset;
    color: transparent;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-cell .i-cell.step1 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-2-step1@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-cell .i-cell.step2 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-2-step2@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-cell .i-cell.step3 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-2-step3@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-2-sub .c-cell .i-cell.step4 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-2-step4@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-image: url("../images/0-common/i-cell-n-type-3@2x.png");
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-3-finish@2x.png");
    background-position: 0 0;
    background-size: 80px 80px;
    background-repeat: no-repeat;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish .c-txt {
    color: #fff;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish .c-cell .i-cell.step1 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish .c-cell .i-cell.step2 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish .c-cell .i-cell.step3 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub.finish .c-cell .i-cell.step4 {
    all: unset;
    font-size: 0;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-txt {
    all: unset;
    display: table-cell;
    table-layout: fixed;
    width: 80px;
    height: 80px;
    padding: 0 20px;
    line-height: 1.2em;
    font-weight: 800;
    font-size: 15px;
    color: #000;
    letter-spacing: -2.2px;
    vertical-align: middle;
    text-align: center;
    word-break: keep-all;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-cell .i-cell {
    all: unset;
    color: transparent;
    text-indent: -999em;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-cell .i-cell.step1 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-3-step1@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-cell .i-cell.step2 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-3-step2@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-cell .i-cell.step3 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-3-step3@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-notice.type-2 .reward-box .cells-ntype-3-sub .c-cell .i-cell.step4 {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-image: url("../images/0-common/i-cell-n-type-3-step4@2x.png");
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center;
}
.challenge-main .area-result-detail.type-2 .result-box-team.result-box {
    height: 170px;
}
.challenge-main .area-result-detail.type-2 .result-box-team .team-info {
    margin-left: 110px;
    padding-top: 26px;
}
.challenge-main .area-result-detail.type-2 .result-box-team .team-info .team-info-txt .t-name {
    display: inline-block;
    width: 400px;
    margin: 0;
    letter-spacing: -2px;
}
.challenge-main .area-result-detail.type-2 .result-box-team .team-bottom {
    bottom: 20px;
}
.challenge-main .area-result-detail.type-2 .mission-state-box {
    height: 60px;
}
.challenge-main .area-result-detail.type-2 .mission-state-box .state-mission-list {
    display: flex;
    align-items: center;
    justify-content: center;
}
.challenge-main .area-result-detail.type-2 .mission-state-box .state-mission-list .state-title {
    display: inline-block;
    margin: 0 20px 0 0;
}
.challenge-main .area-result-detail.type-2 .mission-state-box .state-mission-list .state-number {
    display: inline-block;
    margin: 0;
}
.challenge-main .area-result-detail.type-2 .mission-state-box .state-mission-list::before {
    height: 40px;
    margin-top: -19px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my {
    height: 372px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .result-box-my-wrap {
    padding: 15px 20px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-center-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 315px;
    margin-top: 15px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 {
    width: 510px;
    height: 260px;
    padding: 50px 0;
    display: flex;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2.mvp {
    padding: 32px 0;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2.m-state-fail {
    margin-top: 30px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    margin: -40px;
    box-sizing: border-box;
    border-radius: 50%;
    box-shadow: 3px 4px 12px 0 rgba(146, 148, 243, 0.4);
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 .state-mission-list{
    margin: -5px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num3 .state-mission-list{
    margin: -15px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list.max-txt {
    width: 166px;
    height: 166px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list.min-txt {
    width: 114px;
    height: 114px;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(1) {
    align-self: flex-start;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(1) .state-mission-list {
    background: #f46dd3;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num1 div:nth-child(1) {
    align-self: center;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 div:nth-child(1) {
    align-self: center;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(2) {
    align-self: flex-end;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(2) .state-mission-list {
    background: #11bdb5;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 div:nth-child(2) {
    align-self: center;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(3) {
    align-self: flex-start;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(3) .state-mission-list {
    background: #986af5;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(4) {
    align-self: flex-end;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(4) .state-mission-list {
    background: #7bb2f5;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(5) {
    align-self: flex-start;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp div:nth-child(5) .state-mission-list {
    background: #9294f3;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list .state-txt {
    display: table-cell;
    table-layout: fixed;
    padding: 0 13px;
    box-sizing: border-box;
    line-height: 19px;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -1.2px;
    color: #fff;
    word-break: keep-all;
}
.challenge-main .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 + .my-bottom {
    position: relative;
    left: 10px;
    bottom: 10px;
    width: 490px;
    height: 55px;
    border-top: 0;
}
.challenge-main .area-result-alone .area-mission-map.type-2 {
    left: 30px;
    height: 632px;
}
.challenge-main .area-result-alone .area-mission-map .area-mission-wrap .mission-body .mission-list .i-m-start span {
    color: #e9d8ff;
    -webkit-animation: none;
    animation: none;
}
.challenge-main .area-result-alone .area-mission-map .area-mission-wrap .mission-body .mission-list .i-m-complete span {
    -webkit-animation: none;
    animation: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 {
    top: 20px;
    left: 460px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my {
    width: 790px;
    height: 632px;
    margin-top: 0;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .result-box-my-wrap {
    padding: 25px 30px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .title-result-2 {
    position: relative;
    padding-left: 24px;
    font-weight: 800;
    font-size: 22px;
    color: #222;
    letter-spacing: -1.6px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .title-result-2::before {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 17px;
    height: 21px;
    background-image: url("../images/2-challenge-result/i-title-my-analysis@2x.png");
    background-position: 0 0;
    background-size: 17px 21px;
    background-repeat: no-repeat;
    margin-top: -10px;
    content: "";
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box {
    height: 85px;
    margin-top: 15px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box::after {
    content: "";
    display: block;
    padding-bottom: 25px;
    border-bottom: dashed 1px #d6d6fb;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 {
    width: 730px;
    height: 363px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list.max-txt .state-txt {
    width: 189px;
    height: 189px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list.min-txt .state-txt {
    width: 150px;
    height: 150px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp .state-mission-list .state-txt {
    padding: 0 13px;
    line-height: 22px;
    font-size: 18px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num1 .state-mission-list {
    display: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num1 .state-mission-list:nth-child(1) {
    display: block;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 .state-mission-list {
    display: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 .state-mission-list:nth-child(1) {
    display: block;
    top: 39px;
    left: 159px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num2 .state-mission-list:nth-child(2) {
    display: block;
    bottom: 69px;
    left: 331px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num3 .state-mission-list {
    display: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num3 .state-mission-list:nth-child(1) {
    display: block;
    top: 64px;
    left: 87px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num3 .state-mission-list:nth-child(2) {
    display: block;
    bottom: 73px;
    left: 266px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num3 .state-mission-list:nth-child(3) {
    display: block;
    top: 13px;
    left: 407px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num4 .state-mission-list {
    display: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num4 .state-mission-list:nth-child(1) {
    display: block;
    top: 54px;
    left: 69px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num4 .state-mission-list:nth-child(2) {
    display: block;
    bottom: 58px;
    left: 242px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num4 .state-mission-list:nth-child(3) {
    display: block;
    top: 5px;
    left: 354px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num4 .state-mission-list:nth-child(4) {
    display: block;
    bottom: 193px;
    left: 496px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list {
    display: none;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list:nth-child(1) {
    display: block;
    top: 15px;
    left: 30px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list:nth-child(2) {
    display: block;
    bottom: 6px;
    left: 130px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list:nth-child(3) {
    display: block;
    top: 70px;
    left: 260px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list:nth-child(4) {
    display: block;
    bottom: 61px;
    left: 388px;
}
.challenge-main .area-result-alone .area-result-detail.type-2 .result-box.result-box-my .mission-state-box-ty2 .state-mission-grp.num5 .state-mission-list:nth-child(5) {
    display: block;
    top: 47px;
    right: 35px;
}
