.share-loading-box {
  display: inline-block;
  width: 400px;
  height: 100px;
  line-height: 100px;
  border-radius: 30px;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
}

.zte .share-loading-box.loading-new {
  width: 1334px;
  height: 800px;
}

.share-loading-box.loading-new {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1280px;
  height: 752px;
  margin: 0 auto;
  font-size: 0;
  color: #666;
  border-radius: 0;
  background: #e6e6ff url('../images/share/bg-loading@2x.webp') no-repeat;
  background-size: cover;
}

.share-loading-box.loading-new.full {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.share-loading-box.loading-new .loading-dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 44%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.share-loading-box.loading-new .loading-dot .dot {
  width: 4px;
  height: 4px;
  background: #9294f3;
  border-radius: 50%;
  position: absolute;
}
.share-loading-box.loading-new .loading-dot .dot.d1 {
  left: -2px;
  top: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d2 {
  right: -2px;
  top: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d3 {
  left: -2px;
  bottom: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d4 {
  right: -2px;
  bottom: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d5 {
  right: -2px;
  bottom: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d7 {
  left: -2px;
  bottom: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d8 {
  right: -2px;
  top: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d9 {
  left: -2px;
  top: -2px;
}
.share-loading-box.loading-new .loading-dot .dot.d10 {
  right: -42px;
  bottom: -42px;
  width: 0;
  height: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d11 {
  left: -52px;
  bottom: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d12 {
  right: -52px;
  top: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d13 {
  right: -52px;
  top: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d14 {
  left: -2px;
  bottom: -52px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d15 {
  right: -2px;
  top: -52px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d16 {
  left: -52px;
  bottom: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d17 {
  right: 88px;
  top: -44px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d18 {
  left: 47px;
  bottom: -51px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d19 {
  bottom: -51px;
  left: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d20 {
  left: -51px;
  bottom: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d21 {
  left: -52px;
  top: -1px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d22 {
  top: -52px;
  right: -2px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d23 {
  right: 48px;
  top: -52px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d24 {
  right: -50px;
  bottom: 48px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .dot.d25 {
  right: -51px;
  top: 47px;
  opacity: 0;
}
.share-loading-box.loading-new .loading-dot .circle {
  width: 4px;
  height: 4px;
  border: 2px solid #9294f3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.share-loading-box.loading-new .loading-dot .circle.c5 {
  width: 30px;
  height: 30px;
  opacity: 0;
}

.share-loading-box.loading-new .txt {
  position: absolute;
  bottom: 237px;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 800;
}

.share-loading-box.loading-new .progress {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 40px;
  display: none !important;
}
